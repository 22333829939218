<template>
    <v-overlay :value="openOverlay" opacity="1" color="primary">
        <v-progress-circular indeterminate color="secondary" size="100" width="4">
            <v-img eager :src="imgIcon" height="74" contain/>
        </v-progress-circular>
    </v-overlay>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
    data: vm => {
        return {
            openOverlay: true,
            delay: 500
        }
    },
    computed: {
        ...mapGetters('auth',
            ['hasToken']
        ),
    },
    async mounted() {
        const vm = this
        await vm.loadUser()
    },
    methods: {
        async loadUser() {
            const vm = this

            if (!vm.hasToken) return vm.close(true)

            await vm.$store.dispatch('auth/fetchAuthenticatedUser').catch(err => {
                vm.$store.dispatch('auth/refreshToken').catch(err => {
                    return vm.close(true)
                })
            })

            vm.close()
        },
        close(error = false) {
            const vm = this

            setTimeout(() => {
                vm.openOverlay = false
            }, (vm.delay))

            setTimeout(() => {
                error ? vm.$emit('error') :
                    vm.$emit('success')
            }, (vm.delay + 500))
        }
    },
}

</script>


<style scoped>

</style>
