<template>
    <app-loader @success="onLoadSuccess" @error="onLoadError"/>
</template>

<script>

import AppLoader from '@/components/entrance/AppLoader'
import {mapGetters} from "vuex";

export default {
    components: {
        AppLoader,
    },
    computed: {
        ...mapGetters('auth',
            ['clientCompletedQuiz', 'hasSignature']
        ),
    },
    methods: {
        onLoadSuccess() {
            const vm = this
            if (!vm.isLoggedIn) return vm.goReplace({name: 'login'})
            if (vm.isClient && !vm.clientCompletedQuiz) return vm.goTo({name: 'client.questionnaire'})
            if (vm.isDoctor && !vm.hasSignature) return vm.goTo({name: 'doctor.signature'})
            return vm.goHome()
        },
        onLoadError() {
            const vm = this
            return vm.goReplace({name: 'login'})
        }
    }
}

</script>
